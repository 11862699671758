import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PricingListWrapper from "views/photographerDashboard/components/princingListWrapper";
import {
  GET_PUBLIC_PLANS,
  GET_USER_DETAILS,
  GET_USER_ANALYTICS,
} from "redux-store/sagas/saga-actions";
import {
  getWhatsappMessage,
  slackMessage,
  slackNoAuthMessage,
} from "utils/helpers/subscription";
import { useSearchParams } from "react-router-dom";
import "./Mobile.css";
import DefaultButton from "views/components/button/DefaultButton";
import UserDetailsForm from "views/components/modals/UserDetailsForm";
import Spinner from "views/components/loader/Spinner";
import useOrder from "hooks/useOrder";
import useAuth from "hooks/useAuth";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import {
  clearPaymentResponse,
  setSlackMessage,
} from "redux-store/slices/globals";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import { toast } from "react-toastify";
import useSubscription from "views/subscription/hooks/useSubscription";
import PlanUpgrade from "views/components/modals/PlanUpgrade";
import K from "constants/plans";
import { lsProxy } from "utils/helpers/localstorage";

const MODALS = {
  DETAILS: "details",
  UPGRADE: "upgrade",
  NONE: "none",
};
const SEND_TYPES = {
  WHATSAPP: "whatsapp",
  EMAIL: "email",
  NONE: "none",
};

const MobilePricing = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const [pack, setPack] = React.useState({
    plan: 1,
    yearly: true,
    details: {},
  });
  const [modal, setModal] = React.useState({
    type: MODALS.NONE,
  });
  const [sendType, setSendType] = React.useState(SEND_TYPES.NONE);
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    phone: "",
    cc: "+91",
  });

  const { orderDetails, paymentLink, plansLoading } = useSelector(
    (state) => state.subscription
  );
  const { paymentResponse, isUserInIndia } = useSelector(
    (state) => state.globals
  );

  const frontendType = params.get("frontendType") || "web";
  const userType = params.get("userType");

  const {
    createOrder,
    createOrderVisitor,
    createPaymentLink,
    createPaymentLinkVisitor,
    handlePayment,
  } = useOrder(frontendType);
  const { isAuthenticated } = useAuth();

  const { getMyActivePlan, getMySubscriptionPlanAPI } = useSubscription();
  const {
    userType: lsUserType,
    billing_amount,
    billing_amount_USD,
    plan_id,
  } = getMyActivePlan() || {};

  const billAmt = isUserInIndia ? billing_amount : billing_amount_USD;

  const slkMsg = isAuthenticated
    ? slackMessage(
        pack.details?.packageName,
        pack.yearly ? "yearly" : "monthly",
        frontendType,
        billAmt > 0
      )
    : slackNoAuthMessage(
        pack.details?.packageName,
        pack.yearly ? "yearly" : "monthly",
        user,
        userType,
        frontendType
      );

  // open the razorpay payment modal everytime the order id is updated
  useEffect(() => {
    if (orderDetails?.id) {
      handlePayment();
    }
  }, [orderDetails]);

  useEffect(() => {
    if (paymentLink?.short_url) {
      window.open(paymentLink.short_url, "_blank");
      setModal({ type: MODALS.NONE });
    }
  }, [paymentLink]);

  useEffect(() => {
    setModal({ type: MODALS.NONE });
  }, [paymentResponse]);

  useEffect(() => {
    if (isAuthenticated || params.has("token")) {
      if (params.has("token"))
        lsProxy.setItem("token", "Bearer " + params.get("token"));
      dispatch({
        type: GET_USER_DETAILS,
      });
      dispatch({ type: GET_USER_ANALYTICS });
      getMySubscriptionPlanAPI();
    }

    dispatch({
      type: GET_PUBLIC_PLANS,
      payload: {
        userType,
      },
    });
  }, [params]);

  useEffect(() => {
    if (userType || lsUserType) {
      setParams({
        userType: userType || lsUserType,
        ...(params.has("frontendType") ? { frontendType } : {}),
      });
    }
  }, []);

  const updatePack = (data) => {
    setPack((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handlePay = () => {
    if (lsUserType && lsUserType != pack?.details?.userType)
      return toast.error(
        "You can't purchase this plan as your user type is different."
      );

    if (isAuthenticated) {
      sendSlackMessage(slkMsg, "#mobile-pricing");

      if (billAmt > 0) {
        dispatch(setSlackMessage(slkMsg));
        if (
          pack?.details?.price > billAmt &&
          K.PREMIUM_YEARLY_PLANS[userType || lsUserType].includes(plan_id)
        ) {
          // do nothing
        } else return setModal({ type: MODALS.UPGRADE });
      }

      if (process.env.REACT_APP_STANDARD_CHECKOUT === "true")
        return createOrder(pack?.details);
      createPaymentLink(pack?.details);
    } else {
      setModal({ type: MODALS.DETAILS });
    }
  };

  const handlePayUnauth = (e) => {
    e.preventDefault();

    setModal({ type: MODALS.NONE });
    sendSlackMessage(slkMsg, "#mobile-pricing");

    if (sendType == SEND_TYPES.WHATSAPP)
      window.open(getWhatsappMessage(slkMsg));
    else if (sendType == SEND_TYPES.EMAIL)
      window.open(
        `mailto:sales@kwikpic.in?subject=Account Upgrade&body=${slkMsg}`
      );

    if (sendType != SEND_TYPES.NONE) return setSendType(SEND_TYPES.NONE);

    if (process.env.REACT_APP_STANDARD_CHECKOUT === "true")
      return createOrderVisitor(user, pack?.details);
    createPaymentLinkVisitor(user, pack?.details);
  };

  return (
    <div className="mobile-pricing">
      <div className="mobile-header-upgrade">
        <img src="/assets/images/global-images/logo.png" />
        <div>
          <span>UPGRADE YOUR PLAN</span>
        </div>
      </div>
      <div className="container p-0">
        <PricingListWrapper updatePack={updatePack} />
      </div>
      <div className="mobile-contact-us">
        <div>
          <DefaultButton
            title="Proceed to Pay"
            className="font-bold py-2 px-3 h-auto"
            onClick={handlePay}
          />
        </div>
        <div className="or-div">or</div>
        <div>
          <p className="mb-2 font-12">Get Payment Link via</p>
          <div className="d-flex justify-content-center align-items-center">
            <img
              role="button"
              src="/assets/icons/social/whatsapp.png"
              className="social-icon mx-2"
              onClick={() => {
                if (!isAuthenticated) {
                  setSendType(SEND_TYPES.WHATSAPP);
                  return setModal({ type: MODALS.DETAILS });
                }
                window.open(getWhatsappMessage(slkMsg));
              }}
            />
            <img
              src="/assets/icons/social/email.png"
              className="social-icon mx-2"
              onClick={() => {
                if (!isAuthenticated) {
                  setSendType(SEND_TYPES.EMAIL);
                  return setModal({ type: MODALS.DETAILS });
                }
                window.open(
                  `mailto:sales@kwikpic.in?subject=Account Upgrade&body=${slkMsg}`
                );
              }}
            />
          </div>
        </div>
      </div>

      <UserDetailsForm
        show={modal.type === MODALS.DETAILS}
        setUser={setUser}
        user={user}
        onSubmit={handlePayUnauth}
        onCancel={() => {
          setModal({ type: MODALS.NONE });
          setUser({
            name: "",
            email: "",
            phone: "",
            cc: "+91",
          });
        }}
        type={userType}
      />
      <PlanUpgrade
        show={modal.type === MODALS.UPGRADE}
        hide={() => setModal({ type: MODALS.NONE })}
      />
      <ConfirmationModal
        show={paymentResponse?.title}
        onCancel={() => {
          dispatch(clearPaymentResponse());
          window.location.reload();
        }}
        title={paymentResponse?.title}
        footer={false}
      >
        <p className="text-center font-15 mb-3">{paymentResponse?.details}</p>
      </ConfirmationModal>
      <Spinner loading={plansLoading || isUserInIndia === null} />
    </div>
  );
};

export default MobilePricing;
