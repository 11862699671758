import { toast } from "react-toastify";
import { getCompressedURL, getCFURL } from "utils/helpers";
import { AXIOS } from "utils/setup/axios";
// import { allImagesType } from "views/routes/GallaryRoute";
import { IMAGE_SIZE_DIFFERENCE } from "./components/FolderDownloads";
import {
  getLocalStorageUser,
  groupHasImagesSelected,
} from "utils/helpers/localstorage";
import axios from "axios";
import { detect } from "detect-browser";
import { getDeviceType } from "hooks/useDetectUserAgent";
import webShare from "utils/helpers/web-share";

export async function getFolderImages(id, groupId) {
  if (id === "All") {
    const response = await AXIOS.get(
      "/api/app/pic/all-pics-download/" + groupId
    );

    const { data } = response?.data || {};
    return data?.pics || [];
  } else if (id === "my-photos" || id === "favorites") {
    const response = await AXIOS.get(`/api/app/pic/list/${groupId}`, {
      params: {
        page: 1,
        limit: 100000, // need to find a fix for it
        ...(id === "favorites" ? { isFavorite: true } : {}),
      },
    });
    return response?.data?.data?.pics || [];
  } else if (id === "purchased") {
    const response = await AXIOS.get(`/api/app/pic/paid-pics/${groupId}`);

    return response?.data?.data?.paidPics || [];
  } else if (id === "selected-images") {
    return groupHasImagesSelected(groupId);
  } else {
    const response = await AXIOS.get("/api/app/groupFolder/folderPics/" + id, {
      params: {
        page: 1,
        limit: 100000, // need to find a fix for it
      },
    });

    const { data } = response?.data || {};
    return data?.allPics || [];
    // if (data) {
    //   const images = [];
    //   data?.pics?.forEach((pic) => images.push(...(pic?.images || [])));
    //   return images;
    // }
    // return [];
  }
}

export async function* getFilesRecursively(entry) {
  if (entry.kind === "file") {
    const file = await entry.getFile();
    if (file !== null) {
      yield file;
    }
  } else if (entry.kind === "directory") {
    for await (const handle of entry.values()) {
      yield* getFilesRecursively(handle);
    }
  }
}

/**
 * Downloads a single image with error check, used with the function downloadImages
 * @param {Object} pic
 * @param {String} directory
 */
export const downloadImage = async (pic, directory) => {
  const { downloadURL, url, folderPrefix } = pic;
  let result = true;
  const { fileName } = createFilename(url);

  try {
    let _directory = directory;
    if (folderPrefix) {
      try {
        _directory = await _directory.getDirectoryHandle(folderPrefix.trim(), {
          create: true,
        });
      } catch (_) {
        _directory = await _directory.getDirectoryHandle(
          createFolderName(folderPrefix),
          {
            create: true,
          }
        );
      }
    }
    await _directory
      .getFileHandle(fileName, { create: true })
      .then(async (data) => {
        const writable = await data.createWritable();
        let response;
        try {
          response = await fetch(downloadURL);
          if (!response.ok) {
            response = await fetch(url);
          }
          await response.body.pipeTo(writable);
        } catch (err) {
          result = false;

          /*
          Handling for CORS error
          In case of CORS error, response comes as undefined
          Need to check if there's any other case where response is undefined
          */
          if (!response) {
            response = await fetch(url);
            await response.body.pipeTo(writable);
            result = true;
          }
        }

        if (response?.status !== 200) {
          result = false;
        }
      });
    return result;
  } catch (_) {
    return false;
  }
};

/**
 * CHecks results from API and Disk, return an array of non-existing images and count of existing images
 * @param {Array} images Image array from API response
 * @param {Array} imagesNameAndSizes Image array from disk
 * @param {Boolean} originalQuality Whether to download original quality images
 */
export function getToBeDownloadedImages(
  images, // data from api
  imagesNameAndSizes, // data from disk
  originalQuality
) {
  let successResponseCount = 0;
  let toBeDownloadedImages = [];

  images.forEach(async (pic) => {
    const { url, size: compressedSize } = pic;
    const { fileName, fileSize } = createFilename(url);

    const duplicateImage = imagesNameAndSizes.find((file) => {
      return (
        file.name.toLowerCase() === fileName.toLowerCase() &&
        Math.abs(
          Number(originalQuality ? fileSize : compressedSize) -
            Number(file.size)
        ) <= IMAGE_SIZE_DIFFERENCE
      );
    });

    if (duplicateImage) {
      successResponseCount = successResponseCount + 1;
      return;
    }

    // toBeDownloadedImages.push([digitalOceanURL, url]);
    toBeDownloadedImages.push(pic);
  });

  return {
    successResponseCount,
    toBeDownloadedImages,
  };
}

/**
 * Downloads an array of images in a directory, used in bulk downloads
 * @param {Array} images
 * @param {String} directory
 * @param {Number} incrementProgress
 */
export function downloadImages(
  images,
  directory,
  incrementProgress = () => {}
) {
  return new Promise((resolve) => {
    let imagesCount = images.length;
    let imagesDownloaded = 0;
    images.forEach(async (pic) => {
      try {
        const result = await downloadImage(pic, directory);

        imagesDownloaded = imagesDownloaded + 1;

        if (result) {
          incrementProgress();
        } else {
          incrementProgress(undefined, false, pic);
        }
        if (imagesDownloaded === imagesCount) {
          resolve();
        }
      } catch (_) {
        imagesDownloaded = imagesDownloaded + 1;
        incrementProgress(undefined, false, pic?.url);
        if (imagesDownloaded === imagesCount) {
          resolve();
        }
      }
    });
  });
}

/**
 * @param {String} url
 * @returns {Object} filename and fileSize
 */
export function createFilename(url) {
  // url format: timestamp @ size @ filename.extension
  const splittedURL = url.split("@");
  let fileSize = splittedURL[1];

  let start = url?.indexOf("@");
  let result = url?.slice(start + 1);
  let extensionIndex = result?.lastIndexOf(".");

  let fileName =
    result?.slice(result?.indexOf("@") + 1, extensionIndex) +
    "@" +
    fileSize +
    result?.slice(extensionIndex);

  return { fileSize, fileName };
}

/**
 * @param {String} foldername
 * @returns {String} sanitized foldername
 *
 * Todo
 * - Add more regex rules
 */
export const createFolderName = (foldername) =>
  foldername?.replace(/[&/\\#,+()$~%.'":*?<>{}|]/g, "").trim();

export const getHeightAndWidhtOfImage = ({
  windowWidth,
  photoSize,
  padding,
  image,
}) => {
  let imageWidth = windowWidth / 4;
  if (photoSize === 2) {
    imageWidth = windowWidth / 5;
  }
  if (windowWidth < 1024) {
    imageWidth = windowWidth / 2;
  }

  if (padding === 1) {
    imageWidth -= 12;
  } else {
    imageWidth -= 2;
  }

  const ratio = image?.webThumbWidth / image?.webThumbHeight;
  const imageHeight = imageWidth / ratio;

  return {
    imageWidth,
    imageHeight,
  };
};

export const getDataURL = (url, callback) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  var base_image = new Image();
  base_image.crossOrigin = "Anonymous";
  base_image.src = getCFURL({ url });
  base_image.onload = function () {
    canvas.width = base_image.width;
    canvas.height = base_image.height;

    ctx.drawImage(base_image, 0, 0);

    const dataURL = canvas.toDataURL("image/png");
    callback(dataURL);

    canvas.remove();
  };
};

export const detectBrowser = () => {
  let browserName;

  if (navigator.brave) browserName = "brave";
  else browserName = detect(navigator.userAgent)?.name || "other";

  return browserName;
};

export const isDirectorySelectionSupported = () => {
  const browserName = detectBrowser();

  return (
    ["chrome", "edge-chromium", "opera"].includes(browserName) &&
    "showDirectoryPicker" in window
  );
};

/**
 * @param {boolean} isAdmin
 * @param {boolean} allowShare
 * @param {Function} callback
 * @param {"Share"|"Download"} [type="Share"]
 * @returns {void}
 */
export const allowShareDownload = (
  isAdmin,
  allowShare,
  callback,
  type = "Share"
) => {
  if (!isAdmin && !allowShare) {
    toast.error(`${type} is disabled. Contact group admin for access.`);
    return;
  }

  callback && callback();
};

export const getBranchKey = () => {
  const { _id } = getLocalStorageUser();

  switch (_id) {
    case "63236c2ae99b6f638b9da2bd":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_STUDIOSULTANIA,
        name: "Studio Sultania",
      };
    case "632b0f4cf4a399e1ca182fda":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_ANUPAMAGARWAL,
        name: "Anupam Agarwal",
      };
    case "6332bf8f6c598c88e181fbd1":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_MESSIAHPHOTOGRAPHY,
        name: "Messiah Photography",
      };
    case "63f321ccc4a8d81ccc6fa4e1":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_PICKYOUPIC,
        name: "PickYouPic",
      };
    case "63ef938ff00594c46040cad9":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_CYRUSDESAI,
        name: "Cyrus Desai",
      };
    case "6418064849f8b327efeae3f1":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_SILVERLIGHTSTUDIOS,
        name: "Silverlight Studios",
      };
    case "641ebf17d690bda1776887e0":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_LAVYAFILMPRODUCTION,
        name: "Lavya Film Production",
      };
    case "641d8fb3e8f9e54a77f12888":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_DLFILMS,
        name: "DL Films",
      };
    case "646c5c7669c0d7cacd73cbfd":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_PRATIBIMB,
        name: "Kadakmoments",
      };
    case "647efdc86976207ad8c03669":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_AMANPHOTOGRAPHY,
        name: "Aman Photography",
      };
    case "62fcd9dd4a58d64af2a40ba0":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_OPULENNS,
        name: "Opulenns",
      };
    case "63fc4fe6cb3d99f9e1d77f80":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_SAPSTUDIO,
        name: "SAP Studio",
      };
    case "64c0da53eb3d9c5a71cc80b0":
      return {
        key: process.env.REACT_APP_BRANCH_KEY_MISTYVISUALS,
        name: "Misty Visuals",
      };
    default:
      return {
        key: process.env.REACT_APP_BRANCH_KEY_DEFAULT,
        name: "Kwikpic App",
      };
  }
};

/**
 * Function to download image from url,
 * tries to download from cloudfront url first,
 * if it fails, then downloads from digital ocean url
 *
 * @param {String} url
 * @param {Boolean} originalQuality
 * @param {Function} callback
 */
export const handleDownload = (_url, originalQuality, callback) => {
  if (!_url) return;
  callback && callback();
  const url = getCompressedURL(_url);

  const { type, os } = getDeviceType();

  if (type === "desktop" || os === "android")
    return downloadFile(url, originalQuality);

  // try webshare for ios

  const oURL = getCFURL({ url, original: originalQuality });

  webShare(oURL, oURL, () => {
    // if original download fails, try compressed download
    if (originalQuality) {
      const cURL = getCFURL({ url });
      webShare(cURL, cURL, () => downloadFile(url, originalQuality));
    } else downloadFile(url, originalQuality);
  });
};

/**
 * @param {String} awsUrl AWS URL of the file in `compressed` directory (non-cloudfront)
 * @param {Boolean} original Whether to download image from `original` directory
 */
const downloadFile = async (awsUrl, original = false) => {
  if (!awsUrl) return;

  try {
    const originalURL = getCFURL({ url: awsUrl, original });

    const response = await axios.get(originalURL, { responseType: "blob" });

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;

    if (originalURL.includes("@")) {
      const start = originalURL?.indexOf("@", originalURL?.indexOf("@") + 1);
      const result = originalURL?.slice(start + 1);

      link.setAttribute("download", result);
    } else {
      const val = original
        ? originalURL?.split("original/")
        : originalURL?.split("compress/");
      link.setAttribute("download", val[1]);
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (_) {
    if (original) downloadFile(awsUrl, false);
    else toast.error("Error in downloading");
  }
};
