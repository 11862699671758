import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImageHoverOptions from "./ImageHoverOptions";
import ImageSelector from "./ImageSelector";
import { getHeightAndWidhtOfImage } from "views/download/helpers";
import { modifyGallaryRoute } from "utils/helpers/routes";
import useSize from "hooks/useSize";
import cx from "classnames";
import css from "./FolderImages.module.css";
import { getDOURL } from "utils/helpers";

const ProductSalesIcons = React.lazy(() => import("./ProductSalesIcons"));

const ImageWithOptions = React.memo(
  ({
    image,
    padding,
    photoSize,
    windowWidth,
    folderId,
    isSelected,
    isForProductSale,
    currentUserId,
    loading,
    setLoading,
    setRemoveLoader,
    imagesRefs,
    removedImagesRef,
    setImageView,
    removeLoader,
    index,
    removeFromCart,
    addToCart,
    toggleFavorite,
    downloadWithDispatch,
    isAdmin,
    allowDownload,
    selectMode,
    toggleSelect,
  }) => {
    const { imageWidth, imageHeight } = getHeightAndWidhtOfImage({
      windowWidth,
      photoSize,
      padding,
      image,
    });
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const { width } = useSize();

    const _id =
      folderId == "my-photos" ||
      folderId == "purchased" ||
      folderId == "deleted"
        ? image?.imageId
        : image?._id;

    const authCode = image?.authCode ? image?.authCode != "NP" : false;

    const enableSelectoInDeleted = folderId === "deleted" ? isAdmin : true;

    const toggle = () => {
      toggleSelect(_id, image?.url, image?.authCode, image?.size, index);
    };

    return (
      <li
        className={cx("photo-item", { [css.selectMode]: selectMode })}
        key={_id}
        draggable={false}
        data-img-id={_id}
        data-img-url={image?.url}
        data-img-authcode={image?.authCode}
        data-img-size={image?.size}
        data-img-index={index}
      >
        <div
          className={cx("folder-imgs-image position-relative", {
            [css.selected]: isSelected,
          })}
          style={{
            width: `${imageWidth}px`,
            height: `${imageHeight}px`,
            position: "relative",
          }}
          id={"image_container-" + _id}
          draggable={false}
        >
          <React.Suspense fallback={<></>}>
            {isForProductSale && (
              <ProductSalesIcons
                authCode={authCode}
                uploadedByCurrentUser={image?.uploadedBy == currentUserId}
                src="listings"
              />
            )}
          </React.Suspense>

          <img
            data-img-id={_id}
            data-img-url={image?.url}
            data-img-authcode={image?.authCode}
            data-img-size={image?.size}
            data-img-index={index}
            className={css.image}
            id={"image-" + _id}
            draggable={false}
            onLoad={(e) => {
              if (loading) {
                setLoading(false);
              }
              setRemoveLoader((prev) => ({ ...prev, [_id]: true }));
              imagesRefs.current[_id] = e.target?.src;
              removedImagesRef.current[_id] = e.target?.src;
              e.target.style.height = "100%";
              e.target.style.width = "100%";
              e.target.style.transform = "scale(1)";
            }}
            onError={(e) => {
              const webThumbURL = getDOURL({
                url: image?.url,
                thumb: true,
              });
              imagesRefs.current[_id] = webThumbURL;
              removedImagesRef.current[_id] = webThumbURL;
              if (e.target.src !== webThumbURL) {
                e.target.src = webThumbURL;
              }
            }}
            onClick={() => {
              if (selectMode) return toggle();

              navigate(modifyGallaryRoute({ imageId: _id }, params));
              setImageView({
                show: true,
                selectedID: _id,
              });
            }}
          />

          {!removeLoader[_id] && (
            <div id={"loader-" + _id} className="gallery-img-spinner-loader" />
          )}

          {!selectMode && (
            <ImageHoverOptions
              isForProductSale={isForProductSale}
              image={image}
              authCode={authCode}
              currentUserId={currentUserId}
              folderId={folderId}
              imageId={_id}
              index={index}
              removeFromCart={removeFromCart}
              addToCart={addToCart}
              toggleFavorite={toggleFavorite}
              handleDownload={downloadWithDispatch}
              isAdmin={isAdmin}
              allowDownload={allowDownload}
            />
          )}
        </div>

        {width > 768 && enableSelectoInDeleted && (
          <ImageSelector
            selectMode={selectMode}
            isSelected={isSelected}
            toggleSelect={toggle}
          />
        )}
      </li>
    );
  }
);
ImageWithOptions.displayName = "ImageWithOptions";

export default ImageWithOptions;
