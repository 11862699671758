import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  GET_ANONYMOUS_USER_IMAGES,
  UPLOAD_ANONYMOUS_AVATAR,
} from "redux-store/sagas/saga-actions";
import Spinner from "views/components/loader/Spinner";
import SelfieModal from "views/auth/components/SelfieModal";
import useSize from "hooks/useSize";
import {
  ImageIcon,
  WebCameraCard,
  dataURLtoFile,
} from "views/auth/user/ClickSelfie";
import { lsProxy, ssProxy } from "utils/helpers/localstorage";

const AnonymousSelfies = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { loading, anonymousUser } =
    useSelector((state) => state.anonymous) || {};
  const { width } = useSize();
  const [retry, setRetry] = useState(0);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [showTip, setShowTip] = React.useState(true);

  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const adminToken = params.get("adminToken");
  const groupId = params.get("groupId");

  const id = anonymousUser?._id || anonymousUser?.user?._id;
  const isComparisonDone = anonymousUser?.user?.isComparisonDone;
  const isError = anonymousUser?.user?.isError;

  useEffect(() => {
    if (adminToken) ssProxy.setItem("adminToken", adminToken);
  }, []);

  useEffect(() => {
    let interval;

    if (!loading && id) {
      setIsLoading(true);
      // logout and set anonymous user
      lsProxy.clear();
      lsProxy.setItem("anonymous", "anonymous");

      interval = setInterval(() => {
        setRetry((prev) => prev + 1);
        dispatch({
          type: GET_ANONYMOUS_USER_IMAGES,
          payload: {
            userId: id,
            groupId,
          },
        });
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [id, loading]);

  useEffect(() => {
    if (isComparisonDone || isError || retry > 2) {
      setIsLoading(false);

      navigate(
        `/anonymous/gallery?groupId=${groupId}&anonymousUserId=${id}&folderId=my-photos`,
        {
          state: {
            newUser: true,
          },
        }
      );
    }
  }, [id, isComparisonDone, isError, retry]);

  let videoConstraints;
  if (width < 768) {
    videoConstraints = {
      width,
      height: 300,
      // aspectRatio: 2,
      facingMode: "user",
    };
  } else {
    videoConstraints = {
      width: 500,
      height: 300,
      // aspectRatio: 2,
      facingMode: "user",
    };
  }

  function getInnerWidth() {
    if (width < 768) {
      return width;
    } else {
      return 300;
    }
  }

  function handleSubmit() {
    const avatar = dataURLtoFile(imgSrc, "anonymous-avatar.jpg");
    const data = {
      avatar,
      groupId: params.get("groupId"),
      adminToken,
    };
    dispatch({
      type: UPLOAD_ANONYMOUS_AVATAR,
      payload: data,
    });
  }

  return (
    <div className="clickSelfiePage">
      <Spinner loading={loading || isloading} />
      {/* <div className="hideOnMobile">
        <CustomHeader />
      </div> */}

      {showTip && (
        <div className="useCamera">
          <div className="cameraIcon">
            <img src="/assets/images/icons/camera.png" alt="camera" />
          </div>
          <span className="thick-font font-15 dark-gray-text">
            If your camera is not loading,{" "}
            <a
              href="https://kwikpic-in.app.link/gioVNGlkltb"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Here to use our iOS/Android app
            </a>
          </span>
          <div className="closeIcon" onClick={() => setShowTip(false)}>
            <img src="/assets/images/icons/close.png" alt="close" />
          </div>
        </div>
      )}

      <div className="pageContainer">
        <div className="clickIcons hideOnMobile">
          <ul>
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/face.png"
              imgAlt="selfie1"
              text="Only 1 face"
              textStyle="font-thick font-15 blue-text"
            />
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/blur.png"
              imgAlt="selfie2"
              text="No blur"
              textStyle="font-thick font-15 blue-text"
            />
          </ul>
        </div>

        <div className="clickCamera">
          <div className="pageHeading align-items-center text-center">
            <h2 className="font-thick click-selfie-text">Click a Selfie</h2>
            <p className="font-15 font-thick dark-gray-text">
              Please take a clear selfie for best results
            </p>
          </div>
          <div className="clickCameraCover">
            <WebCameraCard
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              getInnerWidth={getInnerWidth}
              videoConstraints={videoConstraints}
            />
          </div>
          {!imgSrc && (
            <div className="clickIcons showOnMobile">
              <ul>
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/face.png"
                  imgAlt="selfie1"
                  text="Only&nbsp;1&nbsp;face"
                  textStyle="font-thick font-13 blue-text"
                />
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/blur.png"
                  imgAlt="selfie2"
                  text="No&nbsp;blur"
                  textStyle="font-thick font-13 blue-text"
                />
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/mask.png"
                  imgAlt="selfie1"
                  text="No&nbsp;Mask"
                  textStyle="font-thick font-13 blue-text"
                />
                <ImageIcon
                  imgSrc="/assets/images/icons/selfie/spect.png"
                  imgAlt="selfie2"
                  text="No&nbsp;spectacles"
                  textStyle="font-thick font-13 blue-text"
                />
              </ul>
            </div>
          )}
          {imgSrc && (
            <div className="retakeCard">
              <div className="d-flex justify-content-center mt-2">
                <button
                  className="primarySmallBtn font-thick font-15"
                  onClick={() => setImgSrc(null)}
                >
                  Retake
                </button>
                <SelfieModal onSubmit={handleSubmit} setImgSrc={setImgSrc} />
              </div>

              <span className="font-thick gray-text">
                By clicking on Submit you are agreeing to Kwikpic’s{" "}
                <a
                  href="https://kwikpicmarketing.s3.ap-south-1.amazonaws.com/Kwikpic+Ai+Solutions's+TNC.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://kwikpicmarketing.s3.ap-south-1.amazonaws.com/Kwikpic+Ai+Solutions+Privacy+Policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </span>
            </div>
          )}
        </div>

        <div className="clickIcons hideOnMobile">
          <ul>
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/mask.png"
              imgAlt="selfie1"
              text="No Mask"
              textStyle="font-thick font-15 blue-text"
            />
            <ImageIcon
              imgSrc="/assets/images/icons/selfie/spect.png"
              imgAlt="selfie2"
              text="No spectacles"
              textStyle="font-thick font-15 blue-text"
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AnonymousSelfies;
