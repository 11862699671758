import React from "react";
import { useSearchParams } from "react-router-dom";
import { GET_PUBLIC_PLANS } from "redux-store/sagas/saga-actions";
import { useDispatch, useSelector } from "react-redux";
import PricingListWrapper from "views/photographerDashboard/components/princingListWrapper";
import SelectUserTypeModal from "views/components/modals/SelectUserTypeModal";
import PaymentLink from "views/components/modals/PaymentLink";
import { slackMessage, slackNoAuthMessage } from "utils/helpers/subscription";
import "./Pricing.css";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import UserDetailsForm from "views/components/modals/UserDetailsForm";
import useOrder from "hooks/useOrder";
import useAuth from "hooks/useAuth";
import {
  clearSelectedPlan,
  clearPaymentResponse,
  setSlackMessage,
} from "redux-store/slices/globals";
import ConfirmationModal from "views/components/modals/ConfirmationModal";
import Spinner from "views/components/loader/Spinner";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import PlanUpgrade from "views/components/modals/PlanUpgrade";

const MODALS = {
  DETAILS: "details",
  PAY: "pay",
  ALREADY_SUBSCRIBED: "already_subscribed",
  NONE: "none",
};

const Pricing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("userType");
  const [selector, setSelector] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [err, setErr] = React.useState("");
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    phone: "",
    cc: "+91",
  });

  const dispatch = useDispatch();
  const { selectedPlan, paymentResponse, isUserInIndia } = useSelector(
    (state) => state.globals
  );
  const { plansLoading } = useSelector((state) => state.subscription);
  const { isAuthenticated } = useAuth();
  const { createOrderVisitor, createPaymentLinkVisitor } = useOrder();

  React.useEffect(() => {
    let { userType } = getLocalStorageUser();
    if (userType || type) {
      setSearchParams({ userType: userType ?? type });
      setSelector(false);
    } else {
      setSelector(true);
    }

    return () => {
      dispatch(clearSelectedPlan());
      dispatch(clearPaymentResponse());
    };
  }, []);

  React.useEffect(() => {
    dispatch({
      type: GET_PUBLIC_PLANS,
      payload: {
        userType: type ?? "PHOTOGRAPHER",
      },
    });
  }, [type]);

  React.useEffect(() => {
    if (selectedPlan?.plan_id) {
      if (isAuthenticated) {
        if (selectedPlan?.alreadySubscribed)
          setShowModal(MODALS.ALREADY_SUBSCRIBED);
        else setShowModal(MODALS.PAY);

        const text = slackMessage(
          selectedPlan?.packageName,
          selectedPlan?.period,
          null,
          selectedPlan?.alreadySubscribed
        );

        sendSlackMessage(text, "#landing-pricing");
        dispatch(setSlackMessage(text));
      } else setShowModal(MODALS.DETAILS);
    } else setShowModal(MODALS.NONE);
  }, [selectedPlan]);

  const sendSlackLog = () => {
    const text = slackNoAuthMessage(
      selectedPlan?.packageName,
      selectedPlan?.period,
      user,
      type
    );
    sendSlackMessage(text, "#landing-pricing");
    dispatch(setSlackMessage(text));
  };

  const submitUserForm = (e) => {
    e.preventDefault();
    if (user.phone.length < 6 || user.phone.length > 14) {
      return setErr("Please enter a valid phone number");
    } else {
      setErr("");
    }
    setShowModal(MODALS.PAY);
    sendSlackLog();
    if (process.env.REACT_APP_STANDARD_CHECKOUT === "true")
      return createOrderVisitor(user, selectedPlan);
    createPaymentLinkVisitor(user, selectedPlan);
  };

  return (
    <div className="container pricing-container">
      <div className="pricing-container__header">
        <div>
          <h1 className="pricing-container__text">
            Choose the plan <br /> that&apos;s right for you
          </h1>
          <img src="/assets/images/landing/yellow-divider.png" alt="line" />
        </div>
        <div className="d-flex gap-3">
          <button
            className={`${
              type === "USER" ? "normal-white-btn" : "secondaryButton"
            } font-15 `}
            onClick={() => setSearchParams({ userType: "PHOTOGRAPHER" })}
          >
            Photographers
          </button>
          <button
            className={`${
              type === "USER" ? "secondaryButton" : "normal-white-btn"
            } font-15`}
            onClick={() => setSearchParams({ userType: "USER" })}
          >
            Personal Users
          </button>
        </div>
      </div>

      <PricingListWrapper />
      <SelectUserTypeModal
        selector={selector}
        setSelector={setSelector}
        setSearchParams={setSearchParams}
      />
      <PaymentLink
        show={showModal === MODALS.PAY}
        hide={() => setShowModal(MODALS.NONE)}
      />
      <PlanUpgrade
        show={showModal === MODALS.ALREADY_SUBSCRIBED}
        hide={() => setShowModal(MODALS.NONE)}
      />
      <UserDetailsForm
        show={showModal === MODALS.DETAILS}
        setUser={setUser}
        user={user}
        onSubmit={submitUserForm}
        onCancel={() => setShowModal(MODALS.NONE)}
        err={err}
        type={type}
      />
      <ConfirmationModal
        show={paymentResponse?.title}
        onCancel={() => (window.location.href = "/profile-settings/profile")}
        title={paymentResponse?.title}
        footer={false}
      >
        <p className="text-center font-15 mb-3">{paymentResponse?.details}</p>
      </ConfirmationModal>
      <Spinner loading={plansLoading || isUserInIndia === null} />
    </div>
  );
};

export default Pricing;
