import React, { useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";

const LandingPageHeader = ({ className = "" }) => {
  const [expanded, setExpanded] = useState(false);

  const navigator = useNavigate();
  const login = () => {
    navigator("/auth/login");
  };
  return (
    <Navbar
      sticky="top"
      key="lg"
      expand="lg"
      expanded={expanded}
      className={`navbar-bg thick-font ${className}`}
    >
      <Container className="landing-header">
        <Navbar.Brand as={Link} to="/landing/home">
          <img
            className="logo"
            src="/assets/images/global-images/logo.png"
            alt="logo"
          />
        </Navbar.Brand>
        <div className="create-join-sub">
          <a className="sub-menu" href="/auth/login">
            Create
          </a>
          <a className="sub-menu" href="/landing/join">
            Join
          </a>
        </div>

        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-lg"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
          placement="end"
        >
          <Offcanvas.Header closeButton onHide={() => setExpanded(false)}>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
              <Link to="/landing/home" onClick={() => setExpanded(false)}>
                <img
                  className="logo"
                  src="/assets/images/global-images/logo.png"
                  alt="logo"
                />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="px-4 font-14">
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/landing/photographer-dashboard"
              >
                Photographer Benefits
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/landing/aboutus"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/helpdesk"
                target="_blank"
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/helpdesk"
                target="_blank"
                className={expanded ? "d-block" : "d-none"}
              >
                FAQs
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/blog"
                target="_blank"
              >
                Blog
              </Nav.Link>
              <Nav.Link
                as={Link}
                onClick={() => setExpanded(false)}
                to="/landing/pricing"
              >
                Pricing
              </Nav.Link>
              {/* <Nav.Link> <Link className="semi-black-text" to="/landing/photographer-dashboard" > Photographer Benefits </Link></Nav.Link>
              <Nav.Link> <Link className="semi-black-text" to="/landing/aboutus"  > About Us </Link></Nav.Link>
              <Nav.Link> <Link className="semi-black-text" to="/landing/contactus"  > Contact Us </Link></Nav.Link>
              <Nav.Link> <Link className="semi-black-text" to="/faqs"  > FAQs </Link></Nav.Link> */}
            </Nav>
            <Nav className="px-4 font-14">
              <Nav.Link className="blue-text" href="/landing/join">
                Join a Group
              </Nav.Link>
              <Nav.Link
                className={expanded ? "blue-text d-block" : "d-none"}
                href="/auth/login"
              >
                Create a Group
              </Nav.Link>
            </Nav>
            <button className="signUp signUp-desktop" onClick={login}>
              Sign Up or Login
            </button>
            <div className="button-box">
              <button
                className="download-button mb-3"
                onClick={() => {
                  window.open(
                    "https://kwikpic-in.app.link/cfYvH3yuvtb",
                    "_blank"
                  );
                }}
              >
                Download Kwikpic
              </button>
              <button className="signUp" onClick={login}>
                Sign Up or Login
              </button>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default LandingPageHeader;
